<template>
  <div>
    <div class="cover" v-if="showCover">
      <div class="time">{{time}}</div>
      <img :src="require('@/assets/img/save/cover.png')" alt="">
    </div>
    <div class="save-code" v-if="!showCover && showResult">
      <van-image
          class="shop-img"
          fit="contain"
          :src="img || require('@/assets/img/save/shop-img.png')"
      />
      <div class="shop-info" v-if="id">
        <div class="name">{{ placeName }}</div>
        <div class="level">
          {{bindingType == 1 ? '场所' : bindingType == 2 ? '店铺' : '企业'}}评价等级：
          <span v-if="color == 1" style="color: #26AB4D">绿码</span>
          <span v-if="color == 2" style="color: #FFB967">黄码</span>
          <span v-if="color == 3" style="color: #F8654A">红码</span>
        </div>
      </div>
      <div class="shop-info" style="height: 68px" v-else>尚未绑定店铺</div>
      <div class="enter">
        <div class="left" @click="goProblem()">
          <div class="title">随手报<van-icon style="margin-left: 18px;" size="22" name="arrow" color="#A45B00" /></div>
          <div class="des">社区问题随手拍，即刻反馈马上办</div>
          <div class="icon"></div>
          <div class="icon2"></div>
          <div class="icon3"></div>
        </div>
        <div class="right">
          <div class="bottom" @click="goHelp()">
            <div class="title">我要求助<van-icon style="margin-left: 12px;" size="18" name="arrow" color="#BD2C47" @click="goNotice()" /></div>
            <div class="des">消防求助一键达，极速救援护平安</div>
            <div class="icon"></div>
            <div class="icon2"></div>
            <div class="icon3"></div>
          </div>
          <div class="top" @click="goImprove()">
            <div class="title">自主整改<van-icon style="margin-left: 12px;" size="18" name="arrow" color="#192558" /></div>
            <div class="des">巡查问题自主办，整改到位不拖延</div>
            <div class="icon"></div>
            <div class="icon2"></div>
          </div>
        </div>
      </div>
      <div class="ad">
        <div class="top">
          <div style="display: flex; align-items: end ">
            <div class="title">平安宣传</div>
            <div class="des">今日安全提醒~</div>
          </div>

          <div class="more" @click="goNoticeSafe()">全部<van-icon style="margin-left: 7px;" size="14" name="arrow" color="#787E9A" /></div>
        </div>
        <van-swipe @change="onChange" indicator-color="#6A78B2">
          <van-swipe-item v-for="item in Math.ceil(noticeListSafe.length / 3)" :key="item">
            <div v-for="(i, index) in noticeListSafe.slice((item - 1) * 3, (item - 1) * 3 + 3)" class="list-item" @click="getInfo(i.id)">
              <div class="icon" :style="{'background': index == 0 ? '#69BEFF': index == 1 ? '#FFCB8D' : '#FFBBC8'}"></div>
              <div class="text">{{i.title}}</div>
              <!--              <div class="hot">热</div>-->
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <van-dialog
        v-model="showVerify"
        cancel-button-text="取消"
        confirm-button-text="确定"
        show-confirm-button
        show-cancel-button
        @confirm="verifyConfirm"
        @cancel="showVerify = false">
      <div class="verify">
        <van-field name="联系电话" label="联系电话" placeholder="请输入联系电话" v-model="dataForm.mobile"></van-field>
        <van-field name="短信验证码" label="短信验证码" placeholder="请输入验证码" v-model="dataForm.code">
          <template #button>
            <van-button v-if="btnSendText == '发送验证码'" size="small" type="info" @click="getCode" style="border: none">{{btnSendText}}</van-button>
            <div v-else class="btnSendText">{{btnSendText}}</div>
          </template>
        </van-field>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { getImageStream } from '@/utils/index'
import uploadFile from '@/components/upload/uploadFile'
export default {
  data() {
    return {
      showCover: false,
      time: 3,
      timer: null,
      mobile:'',
      noticeListSafe: [],
      orgId: '',
      noticeType:'',
      url:'https://sgg.hzxh.gov.cn/police-api',
      // url:'http://192.168.0.108:8091',
      // adminurl:'http://192.168.0.76:8080/adminCode',
      bindingType: '',
      showResult: false,
      placeName: '',
      color: '',
      img: '',
      id: '',
      current: 0,
      showVerify: false,
      dataForm: {
        mobile: '',
        code: ''
      },
      btnSendText: '发送验证码',
      btnSendChecked: false,
      timerCode: null,
      address: '',
      contact: '',
      contactMobile: ''
    }
  },
  components: {
    uploadFile
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  created() {
    this.showCover = sessionStorage.getItem('showCover') !== '0'
    if (this.showCover) {
      setTimeout(() => {
        this.showCover = false
        clearInterval(this.timer)
        this.timer = null
        sessionStorage.setItem('showCover', '0')
      }, 3000)
      this.time = 3
      this.timer = setInterval(() => {
        this.time -= 1
      }, 1000)
    }
    if (this.$route.query.domain && this.$route.query.domain != 'null'&&!this.$route.query.p&&!this.$route.query.u) {
      this.$http.changeUrl(this.$route.query.domain, true)
      this.$httpApp.changeUrl(this.$route.query.domain, true)
      this.$httpCustApp.changeUrl(this.$route.query.domain, true)
    }
    this.getScanCodeParams()
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    getInfo(id) {
      this.$store.commit('setNoticeId', id)
      this.$router.push('/notice-info')
    },
    goNoticeSafe() {
      let tmp1=this.orgId|| Vue.prototype.$globalData.userInfo.orgId
      let tmp2=this.noticeType
      this.$router.push({path:'/notice',query:{orgId:tmp1,noticeType:tmp2}})
    },
    goNotice() {
      let tmp1=this.orgId|| Vue.prototype.$globalData.userInfo.orgId
      let tmp2=1
      this.$router.push({path:'/notice',query:{orgId:tmp1,isOpen:tmp2}})
    },
    goProblem() {
      this.$router.push({path:'/saveCode-problem', query: {
          orgId: this.orgId,
          type: this.bindingType,
          address: this.address,
          buildingId: this.id
      }})
    },
    goHelp() {
      this.$router.push({path:'/saveCode-help', query: {
          orgId: this.orgId,
          type: this.bindingType,
          address: this.address,
          buildingId: this.id
      }})
    },
    goImprove () {
      if (!sessionStorage.getItem('userMobile')) {
        this.dataForm.mobile = ''
        this.dataForm.code = ''
        this.showVerify = true
      } else {
        this.$router.push({path:'/saveCode-problem-list', query: {
          orgId: this.orgId,
          mobile: sessionStorage.getItem('userMobile'),
          placeType: this.bindingType == 1 ? 3 : this.bindingType == 2 ? 5 : 6,
          placeId: this.id
        }})
      }
    },
    verifyConfirm () {
      if (!this.dataForm.mobile) {
        return this.$toast.fail('请输入联系电话')
      } else if (!this.btnSendChecked) {
        return this.$toast.fail('请先获取验证码')
      } else if (!this.dataForm.code) {
        return this.$toast.fail('请输入验证码')
      }
      this.$httpCustApp({
        url: 'https://api.hzuht.com/general/verify/verifyCode',
        method: 'post',
        params: this.$httpCustApp.adornParams({
          token: this.dataForm.mobile,
          code: this.dataForm.code
        })
      }).then(({ data }) => {
        if (data && data.code === 0){
          sessionStorage.setItem('userMobile', this.dataForm.mobile)
          this.$router.push({path:'/saveCode-problem-list', query: {
            orgId: this.orgId,
            mobile: this.dataForm.mobile,
            placeType: this.bindingType == 1 ? 3 : this.bindingType == 2 ? 5 : 6,
            placeId: this.id
          }})
        }
      })
    },
    getCode () {
      if (!this.dataForm.mobile) {
        return this.$toast.fail('请输入联系电话')
      } else if (this.btnSendText != '发送验证码') {
        return
      }
      this.$httpCustApp({
        url: 'https://api.hzuht.com/general/verify/sendVerifyCode',
        method: 'post',
        params: this.$httpCustApp.adornParams({
          token: this.dataForm.mobile,
          mobile: this.dataForm.mobile,
          needExist: 1
        })
      }).then(({ data }) => {
        if (data && data.code === 0){
          this.$toast.success ({
            message: '发送成功',
            duration: 1500,
          })
          this.countDown()
          // this.dataForm.code = data.code
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    countDown () {
      let that = this;
      let countDownNum = 60
      this.timerCode = setInterval( () => {
        countDownNum--;
        that.btnSendText = countDownNum
        that.btnSendChecked = true
        if (countDownNum == 0) {
          clearInterval(that.timerCode)
          that.timerCode = null
          that.btnSendText = '发送验证码'
        }
      }, 1000)
    },
    getScanCodeParams() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/scanCode/getScanCodeParams'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$route.query.params|| Vue.prototype.$qrcodeOptions
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          Vue.prototype.$depth = data.data.depth
          Vue.prototype.$orgId = data.data.orgId
          this.orgId = data.data.orgId
          this.$httpCustApp({
            url: this.$httpCustApp.adornUrl('/wxapp/qrCodeInfo/getQrCodeInfoByQrCode'),
            method: 'get',
            params: this.$httpCustApp.adornParams({
              qrCode: data.data.code
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.showResult = true
              this.bindingType = data.info.bindingType
              this.id = data.info.bindingId
              // this.orgId = data.info.community || data.info.street
              this.getNoticeType()
              if (this.id) this.getPlaceInfo()
              // if (this.orgId) {
              //   this.getStreetId(this.orgId)
              // }
            }
          })
        }
      })
    },
    getPlaceInfo () {
      this.$http({
        url: this.$http.adornUrl(this.bindingType == 1 ? '/wxapp/place/info/only/info' : this.bindingType == 2 ? '/wxapp/place/placeinfostreetshop/only/info'
          : '/wxapp/place/placeinfoenterprise/only/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id,
          skipEncrypt: true
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.orgId = data.info.community
          this.placeName = data.info.name
          this.address = data.info.address
          this.contact = data.info.contact
          this.contactMobile = data.info.contactMobile
          this.color = data.info.color || 1
          if (data.info.imgUrl) this.img = getImageStream(data.info.imgUrl.split(',')[0])
        }
      })
    },
    getNoticeType() {
      this.$http({
        url: this.$http.adornUrl(`/sys/dict/listDictByCode`),
        method: 'get',
        params: this.$http.adornParams({
          code: 'noticeType',
          orgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          data.dicts.forEach(item => {
            if (item.label === '平安宣传' && item.parameters.type === 'safetyCode') {
              this.noticeType = item.value
            }
          })
          this.getNoticeSafe()
        }
      })
    },
    getNoticeSafe() {
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl(`/wxapp/commonApp/notice/listWithoutToken`),
        method: 'post',
        params: this.$httpCustApp.adornParams({
          page: 1,
          limit: 4,
          logInOrgId: this.orgId,
          orgUserId: 0,
          noticeType: this.noticeType
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeListSafe=data.page.list
        }
      })
    },
    // 根据社区id获取街道id
    getStreetId(id) {
      this.$http({
        url: this.$http.adornUrl(`/sys/org/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          let street = data.sysOrg.path.split(',')
          let newStreet = street.splice(0, street.length - 1)
          this.streetPath = newStreet.slice(1).toString()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.cover {
  width: 100vw;
  .time {
    font-family: DINPro-Bold;
    font-size: 24px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    position: fixed;
    top: 30px;
    right: 30px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.save-code {
  font-family: PingFangSC-Regular;
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient( 180deg, #6EC2FF 0%, #AFDAFE 15%, #ECF3FF 30%, #F3F6FA 100%);
  //background-image: url("~@/assets/img/save/saveCode-bg2.png");
  //background-size: 750px 560px;
  //background-repeat: no-repeat;
  padding-bottom: 24px;

  .enter {
    background-color: #fff;
    padding: 10px;
    width: 690px;
    margin: 32px auto 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
    font-family: PingFangSC-Regular;
    .left {
      height: 222px;
      background: linear-gradient( 180deg, #FEC787 0%, #FFF7EE 100%);
      border-radius: 16px;
      padding: 26px;
      position: relative;
      .title {
        color: #A45B00;
        font-size: 48px;
        line-height: 56px;
      }
      .des {
        margin-top: 12px;
        color: #D8A86C;
        line-height: 32px;
        font-size: 28px;
        z-index: 999;
        position: relative;
      }
      .icon {
        z-index: 2;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 196px;
        height: 186px;
        background: linear-gradient( 150deg, #FFBA68 0%, #FFD6A4 60%, #FFD8A1 100%);
        border-radius: 50% 50% 16px 50%;
      }
      .icon2 {
        z-index: 2;
        position: absolute;
        bottom: 44px;
        right: 48px;
        width: 98px;
        height: 94px;
        background: linear-gradient( 330deg, #FFE3CB 0%, #FCD3A2 52%, #FFBA68 100%);
        border-radius: 50%;
      }
      .icon3 {
        position: absolute;
        bottom: 0;
        right: 128px;
        width: 136px;
        height: 118px;
        background: linear-gradient( 90deg, #FFE3CB 0%, #FFBF75 100%);
        border-radius: 50%;
      }
    }
    .right {
      display: flex;
      gap: 12px;
      .top {
        flex: 1;
        height: 210px;
        background: linear-gradient( 180deg, #B6DFFF 0%, #E7F4FF 100%);
        border-radius: 8px;
        padding: 24px;
        position: relative;
        .title {
          color: #192558;
          font-size: 40px;
          line-height: 38px;
        }
        .des {
          margin-top: 12px;
          color: #7B819C;
          font-size: 24px;
          line-height: 32px;
          z-index: 999;
          position: relative;
        }
        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 140px;
          height: 132px;
          background: linear-gradient(150deg, #AADBFF 0%, #DFF1FF 100%);
          border-radius: 50% 50% 8px 50%;
          opacity: 0.9;
        }
        .icon2 {
          position: absolute;
          bottom: 30px;
          right: 32px;
          width: 70px;
          height: 68px;
          background: linear-gradient( 150deg, #C0E4FF 0%, #D2ECFF 39%, #E9F6FF 100%);
          border-radius: 50%;
        }
      }
      .bottom {
        flex: 1;
        height: 210px;
        background: linear-gradient( 180deg, #FFD4DD 0%, #FFE9ED 100%);
        box-shadow: 0px 4px 4px 0px #F2F2F2;
        border-radius: 16px;
        padding: 26px;
        position: relative;
        .title {
          color: #BD2C47;
          font-size: 40px;
          line-height: 38px;
        }
        .des {
          margin-top: 12px;
          color: #F6AAB9;
          font-size: 24px;
          line-height: 32px;
          z-index: 999;
          position: relative;
        }
        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 136px;
          height: 128px;
          background: linear-gradient( 150deg, #FFD1DB 0%, #FFDAE2 62%, #FFE7EB 100%);
          border-radius: 50% 50% 8px 50%;
          opacity: 0.9;
        }
        .icon2 {
          position: absolute;
          bottom: 30px;
          right: 32px;
          width: 70px;
          height: 68px;
          background: #FFE7EB;
          border-radius: 50%;
        }
        .icon3 {
          position: absolute;
          bottom: 28px;
          right: 58px;
          width: 48px;
          height: 46px;
          background: linear-gradient( 228deg, #FFC1CE 0%, #FFDDE5 100%);
          border-radius: 50%;
        }
      }
    }
  }

  .ad {
    width: 690px;
    margin: 34px auto;
    height: 286px;
    box-shadow: 0px 8px 8px 0px #EBECEE;
    border-radius: 22px;
    background-color: #fff;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 22px 22px 0 0;
      background: linear-gradient( 360deg, #fff 0%, #AFDCFF 100%);
      padding: 24px;
      .title {
        font-weight: 600;
        font-size: 32px;
        color: #192558;
      }
      .des {
        font-size: 26px;
        color: #22337C;
        margin-left: 18px;
        z-index: 999;
        position: relative;
      }
      .more {
        float: right;
        font-size: 24px;
        color: #787E9A;
      }
    }
    .list-item {
      display: flex;
      align-items: center;
      padding: 0 26px;
      height: 48px;
      line-height: 48px;
    }
    .icon {
      width: 20px;
      height: 20px;
      background: #69BEFF;
      border-radius: 50%;
    }
    .text {
      max-width: 600px;
      margin-left: 8px;
      font-size: 24px;
      color: #7B829C;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .hot {
      width: 40px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #FFE479;
      color: #22337C;
      font-size: 20px;
      border-radius: 10px;
      margin-left: 14px;
    }
  }
}
.save-code-unbind {
  font-family: PingFangSC-Regular;
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient( 180deg, #6EC2FF 0%, #AFDAFE 15%, #ECF3FF 30%, #F3F6FA 100%);
  .content {
    width: 690px;
    height: calc(100vh - 104px);
    background-color: #fff;
    margin: 80px auto 0;
    padding-top: 26px;
    box-shadow: 0px 8px 8px 0px #DEE7F3;
    border-radius: 20px;
  }
  .unbind {
    width: 682px;
    height: 588px;
  }
  .enter {
    width: 690px;
    margin: 32px auto 0;
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Regular;
    background-color: #fff;
    padding: 24px;
    .left {
      width: 306px;
      height: 136px;
      background: linear-gradient( 180deg, #B6DFFF 0%, #E7F4FF 100%);
      border-radius: 8px;
      padding: 24px;
      position: relative;
      .title {
        color: #192558;
        font-size: 32px;
        line-height: 38px;
      }
      .des {
        margin-top: 12px;
        color: #7B819C;
        font-size: 24px;
        line-height: 32px;
        z-index: 999;
        position: relative;
      }
      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 116px;
        height: 110px;
        background: linear-gradient(150deg, #AADBFF 0%, #DFF1FF 100%);
        border-radius: 50% 50% 8px 50%;
        opacity: 0.9;
      }
      .icon2 {
        position: absolute;
        bottom: 24px;
        right: 28px;
        width: 58px;
        height: 56px;
        background: linear-gradient( 150deg, #C0E4FF 0%, #D2ECFF 39%, #E9F6FF 100%);
        border-radius: 50%;
      }
    }
    .right {
      width: 306px;
      height: 136px;
      background: linear-gradient( 180deg, #FFDCE3 0%, #FFEFF2 100%);
      border-radius: 8px;
      padding: 24px;
      position: relative;
      .title {
        color: #BD2C47;
        font-size: 32px;
        line-height: 38px;
      }
      .des {
        margin-top: 12px;
        color: #F6AAB9;
        font-size: 24px;
        line-height: 32px;
        z-index: 999;
        position: relative;
      }
      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 116px;
        height: 110px;
        background: linear-gradient( 150deg, #FFD1DB 0%, #FFDAE2 62%, #FFE7EB 100%);
        border-radius: 50% 50% 8px 50%;
        opacity: 0.9;
      }
      .icon2 {
        position: absolute;
        bottom: 24px;
        right: 28px;
        width: 58px;
        height: 56px;
        background: #FFE7EB;
        border-radius: 50%;
      }
      .icon3 {
        position: absolute;
        bottom: 24px;
        right: 52px;
        width: 42px;
        height: 40px;
        background: linear-gradient( 228deg, #FFC1CE 0%, #FFDDE5 100%);
        border-radius: 50%;
      }
    }
  }
}
.van-swipe {
  height: 212px;
}
.verify {
  padding-top: 30px;
  ::v-deep .van-field__label {
    display: flex;
    align-items: center;
  }
}
.btnSendText {
  color: #1989fa;
  margin-right: 40px;
}
.shop-img {
  width: 690px;
  height: 258px;
  margin: 20px 30px 0;
}

.shop-info {
  font-size: 40px;
  color: #2858A0;
  width: 690px;
  height: 96px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 0px rgba(207,207,207,0.25);
  margin: 24px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px;
  .name {
    font-size: 32px;
    color: #192558;
  }
  .level {
    font-size: 28px;
    color: #595D6B;
  }
}
</style>
